// import React, { useState, useEffect } from 'react';
// import Logo from '../img/RDBS imgs/logo.png';
// import { NavLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch, faCartArrowDown, faUser } from '@fortawesome/free-solid-svg-icons';
// import LogoutIcon from '@mui/icons-material/Logout';
// import { logout } from './Logoutuser';
// import axios from 'axios';

// export default function Navheader() {
//   const [logoutSuccess, setLogoutSuccess] = useState(false);
//   const [activetab, setactivetab] = useState();
//   const [userName, setUserName] = useState({ firstName: '', lastName: '' });
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

//   useEffect(() => {
//     const fetchUserName = async () => {
//       try {
//         const response = await axios.get('/api/user/session');
//         setUserName({
//           firstName: response.data.firstName,
//           lastName: response.data.lastName,
//         });
//       } catch (error) {
//         console.error('Error fetching user name:', error);
//         setUserName({ firstName: '', lastName: '' });
//       }
//     };

//     fetchUserName();
//   }, []);

//   const handleLogout = async () => {
//     try {
//       await logout();
//       setLogoutSuccess(true);
//       setTimeout(() => {
//         setLogoutSuccess(false);
//         window.location.href = '/';
//       }, 3000);
//     } catch (error) {
//       console.error('Logout failed:', error.message);
//     }
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen((prev) => !prev);
//   };

//   return (
//     <div>
//       {logoutSuccess && (
//         <div className="alert alert-success text-center" role="alert">
//           Logout successful!
//         </div>
//       )}
//       <nav className="navbar navbar-expand-lg navbar-light shadow fixed-top" style={{ top: '40px', backgroundColor: 'white' }}>
//         <div className="container d-flex justify-content-between align-items-center">
//           <a className="navbar-brand text-success logo h1 align-self-center" href="index.html">
//             <img className="r-logo" src={Logo} alt="" />
//           </a>

//           <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className="align-self-center collapse navbar-collapse flex-fill d-lg-flex justify-content-lg-between" id="templatemo_main_nav">
//             <div className="flex-fill">
//               <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto">
//                 <li className="nav-item">
//                   <NavLink className="nav-link" to="/">
//                     <div className={`tab-button ${activetab === 0 ? 'active' : ''}`} onClick={() => setactivetab(0)}></div>Home
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink className="nav-link" to="/shop">
//                     <div className={`tab-button ${activetab === 1 ? 'active' : ''}`} onClick={() => setactivetab(1)}></div>Shop
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink className="nav-link" to="/contact">
//                     <div className={`tab-button ${activetab === 2 ? 'active' : ''}`} onClick={() => setactivetab(2)}></div>Contact
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink className="nav-link" to="/about">
//                     <div className={`tab-button ${activetab === 3 ? 'active' : ''}`} onClick={() => setactivetab(3)}></div>About
//                   </NavLink>
//                 </li>
//               </ul>
//             </div>
//             <div className="navbar align-self-center d-flex">
//               <div className="d-lg-none flex-sm-fill mt-3 mb-4 col-7 col-sm-auto pr-3">
//                 <div className="input-group">
//                   <input type="text" className="form-control" id="inputMobileSearch" placeholder="Search" />
//                   <div className="input-group-text">
//                     <FontAwesomeIcon icon={faSearch} />
//                   </div>
//                 </div>
//               </div>
//               <NavLink className="nav-icon d-none d-lg-inline" to="/" data-bs-toggle="modal" data-bs-target="#templatemo_search">
//                 {/* <FontAwesomeIcon icon={faSearch} className="text-dark mr-2" /> */}
//               </NavLink>
//               <NavLink className="nav-icon position-relative text-decoration-none" to="/Addtocart">
//                 <FontAwesomeIcon icon={faCartArrowDown} className="text-dark mr-1" />
//               </NavLink>
//               {userName.firstName && (
//                 <span className="navbar-text" style={{ fontWeight: 'bold', marginRight: '15px' }}>
//                   Hi, {userName.firstName}
//                 </span>
//               )}
//               <div className="nav-icon position-relative text-decoration-none" onClick={toggleDropdown}>
//                 <FontAwesomeIcon icon={faUser} className="text-dark mr-3" style={{ cursor: 'pointer' }} />
//                 {isDropdownOpen && (
//                   <div className="dropdown-menu show"
//                     style={{
//                       position: 'absolute',
//                       top: '40px',
//                       transform: 'translateX(-50%)', /* Adjust to center */
//                       backgroundColor: 'white',
//                       border: '1px solid #ccc',
//                       textAlign: 'center', /* Center text inside */
//                       zIndex: 1000,
//                     }} >
//                     {userName.firstName ? (
//                       <>
//                         <NavLink className="dropdown-item" to="/Userprofile" style={{ color: 'green', backgroundColor: 'white' }}>
//                           Profile
//                         </NavLink>
//                         <NavLink className="dropdown-item" to="/Userpuchasehistory" style={{ color: 'purple', backgroundColor: 'white' }}>
//                           My Orders
//                         </NavLink>
//                         <button className="dropdown-item" onClick={handleLogout} style={{ color: 'red', backgroundColor: 'white' }}>
//                           Logout
//                         </button>
//                       </>
//                     ) : (
//                       <NavLink className="dropdown-item" to="/Loginuser" style={{ color: 'Green', backgroundColor: 'white' }}>
//                         Login
//                       </NavLink>
//                     )}
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// }



import React, { useState, useEffect } from 'react';
import Logo from '../img/RDBS imgs/logo.png';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCartArrowDown, faUser } from '@fortawesome/free-solid-svg-icons';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from './Logoutuser';
import axios from 'axios';

export default function Navheader() {
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [activetab, setactivetab] = useState();
  const [userName, setUserName] = useState({ firstName: '', lastName: '' });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await axios.get('/api/user/session');
        setUserName({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
        });
      } catch (error) {
        console.error('Error fetching user name:', error);
        setUserName({ firstName: '', lastName: '' });
      }
    };

    fetchUserName();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      setLogoutSuccess(true);
      setTimeout(() => {
        setLogoutSuccess(false);
        window.location.href = '/';
      }, 3000);
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  return (
    <div>
      {logoutSuccess && (
        <div className="alert alert-success text-center" role="alert">
          Logout successful!
        </div>
      )}
      <nav className="navbar navbar-expand-lg navbar-light shadow fixed-top" style={{ top: '40px', backgroundColor: 'white' }}>
        <div className="container d-flex justify-content-between align-items-center">
          <a className="navbar-brand text-success logo h1 align-self-center" href="index.html">
            <img className="r-logo" src={Logo} alt="" />
          </a>

          <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="align-self-center collapse navbar-collapse flex-fill d-lg-flex justify-content-lg-between" id="templatemo_main_nav">
            <div className="flex-fill">
              <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    <div className={`tab-button ${activetab === 0 ? 'active' : ''}`} onClick={() => setactivetab(0)}></div>Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/shop">
                    <div className={`tab-button ${activetab === 1 ? 'active' : ''}`} onClick={() => setactivetab(1)}></div>Shop
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contact">
                    <div className={`tab-button ${activetab === 2 ? 'active' : ''}`} onClick={() => setactivetab(2)}></div>Contact
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/about">
                    <div className={`tab-button ${activetab === 3 ? 'active' : ''}`} onClick={() => setactivetab(3)}></div>About
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="navbar align-self-center d-flex">
              <NavLink className="nav-icon position-relative text-decoration-none" to="/Addtocart">
                <FontAwesomeIcon icon={faCartArrowDown} className="text-dark mr-1" />
              </NavLink>
              {userName.firstName && (
                <span className="navbar-text" style={{ fontWeight: 'bold', marginRight: '15px' }}>
                  Hi, {userName.firstName}
                </span>
              )}
              <div
                className="nav-icon position-relative text-decoration-none"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faUser} className="text-dark mr-3" />
                {isDropdownOpen && (
                  <div
                    className="dropdown-menu show"
                    style={{
                      position: 'absolute',
                      top: '25px',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'white',
                      // border: '1px solid #ccc',
                      textAlign: 'center',
                      zIndex: 1000,
                    }}
                  >
                    {userName.firstName ? (
                      <>
                        <NavLink className="dropdown-item" to="/Userprofile" style={{ color: 'green', backgroundColor: 'white' }}>
                          Profile
                        </NavLink>
                        <NavLink className="dropdown-item" to="/Userpuchasehistory" style={{ color: 'purple', backgroundColor: 'white' }}>
                          My Orders
                        </NavLink>
                        <button className="dropdown-item" onClick={handleLogout} style={{ color: 'red', backgroundColor: 'white' }}>
                          Logout
                        </button>
                      </>
                    ) : (
                      <NavLink className="dropdown-item" to="/Loginuser" style={{ color: 'Green', backgroundColor: 'white' }}>
                        Login
                      </NavLink>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

