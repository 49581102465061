// Frontend-friendly version of generateCartOrderNumber
const generateCartOrderNumber = (firstName, lastName) => {
    // Define month mapping
    const monthMapping = {
        'January': 'A', 'February': 'B', 'March': 'C', 'April': 'D', 'May': 'E', 'June': 'F',
        'July': 'G', 'August': 'H', 'September': 'I', 'October': 'J', 'November': 'K', 'December': 'L'
    };

    // Get current date and time
    const now = new Date();
    const day = now.getDate().toString().padStart(2, '0');
    const month = monthMapping[now.toLocaleString('default', { month: 'long' })];
    const year = now.getFullYear().toString().slice(-2); // last 2 digits of the year
    const hour = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');

    // Create a simple hash based on the user's name using a basic algorithm
    const nameHashInput = `${firstName}${lastName}`;
    let nameHash = 0;
    for (let i = 0; i < nameHashInput.length; i++) {
        nameHash = nameHash * 31 + nameHashInput.charCodeAt(i);
    }
    nameHash = Math.abs(nameHash).toString(36).slice(0, 5).toUpperCase(); // Create a 5-character hash

    // Construct the order number with the date, time, and user's simple hash
    const orderNumber = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}${day}${month}${year}${hour}${minutes}${nameHash}`;

    return orderNumber;
};

export default generateCartOrderNumber;
