import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductImage from './Productimage';
import services from './apiServices';

export default function Addtocart() {
  const location = useLocation();
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [message, setMessage] = useState(''); // State to hold the success message
  const [errorMessage, setErrorMessage] = useState(''); // State to hold error messages

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const status = await services.checkLoginStatus();
        setIsLoggedIn(status.isLoggedIn);

        let data;
        if (status.isLoggedIn) {
          data = await services.ViewCARTProducts();
        } else {
          const guestCart = localStorage.getItem('guestCart');
          data = guestCart ? JSON.parse(guestCart) : [];
        }
        setCart(data);
      } catch (error) {
        console.error('Error fetching cart:', error);
      }
    };

    fetchCart();
  }, []);

  const handleRemove = async (productId) => {
    try {
      if (isLoggedIn) {
        await services.removeProductFromCart(productId);
      } else {
        let guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
        guestCart = guestCart.filter(item => item.PROD_ID !== productId);
        localStorage.setItem('guestCart', JSON.stringify(guestCart));
      }
      const updatedCart = isLoggedIn ? await services.ViewCARTProducts() : JSON.parse(localStorage.getItem('guestCart')) || [];
      setCart(updatedCart);
      setMessage('Product removed from cart successfully!'); // Set success message

      // Clear the message after 3 seconds
      setTimeout(() => {
        setMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error removing product:', error);
    }
  };

  const handleCheckboxChange = (productId, quantity, productDetails) => {
    const existingProductIndex = selectedProducts.findIndex(product => product.PROD_ID === productId);

    if (existingProductIndex !== -1) {
      // If the product is already selected, uncheck it (remove from selectedProducts)
      const updatedSelectedProducts = [...selectedProducts];
      updatedSelectedProducts.splice(existingProductIndex, 1);
      setSelectedProducts(updatedSelectedProducts);
    } else {
      // If the product is not selected, add it to selectedProducts with details
      setSelectedProducts([...selectedProducts, { 
        PROD_ID: productId, 
        quantity, 
        PROD_IMG_URL: productDetails.PROD_IMG_URL, 
        PROD_DESP: productDetails.PROD_DESP, 
        PROD_PRICE: productDetails.PROD_PRICE, 
        totalPrice: productDetails.PROD_PRICE * quantity 
      }]);
      setMessage('Product added to selection successfully!'); // Set success message

      // Clear the message after 3 seconds
      setTimeout(() => {
        setMessage('');
      }, 1000);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!isLoggedIn) {
        navigate('/Loginuser');
      } else {
        if (selectedProducts.length > 0) {
          const purchaseResponse = await services.buyProductFromCart(selectedProducts);
          console.log('Purchase successful:', purchaseResponse);
  
          // Update cart and clear the selected products
          const updatedCart = await services.ViewCARTProducts();
          setCart(updatedCart);
          setSelectedProducts([]); // Clear selected products after purchase
          setMessage('Purchase successful!'); // Set success message

          // Clear the message after 3 seconds
          setTimeout(() => {
            setMessage('');
          }, 3000);
  
          // Pass selectedProducts as state to the Productpurchase page
          navigate('/Productpurchase', { state: { selectedProducts } });
        } else {
          setErrorMessage('Please select at least one product to proceed.');
          setTimeout(() => {
            setErrorMessage('');
          }, 1000);
          console.log('No products selected');
        }
      }
    } catch (error) {
      console.error('Error buying product:', error);
    }
  };
  

  const handleIncrement = async (productId) => {
    const updatedCart = cart.map(item => {
      if (item.PROD_ID === productId) {
        item.quantity += 1;
      }
      return item;
    });
    setCart(updatedCart);

    try {
      if (isLoggedIn) {
        await services.updateCart(productId, updatedCart.find(item => item.PROD_ID === productId).quantity);
        setMessage('Product quantity updated successfully!'); // Set success message
      } else {
        localStorage.setItem('guestCart', JSON.stringify(updatedCart));
      }
      // Clear the message after 3 seconds
      setTimeout(() => {
        setMessage('');
      }, 1000);
    } catch (error) {
      console.error('Error updating product quantity:', error);
    }
  };

  const handleDecrement = async (productId) => {
    const updatedCart = cart.map(item => {
      if (item.PROD_ID === productId && item.quantity > 1) {
        item.quantity -= 1;
      }
      return item;
    });
    setCart(updatedCart);

    try {
      if (isLoggedIn) {
        await services.updateCart(productId, updatedCart.find(item => item.PROD_ID === productId).quantity);
        setMessage('Product quantity updated successfully!'); // Set success message
      } else {
        localStorage.setItem('guestCart', JSON.stringify(updatedCart));
      }
      // Clear the message after 1 seconds
      setTimeout(() => {
        setMessage('');
      }, 1000);
    } catch (error) {
      console.error('Error updating product quantity:', error);
    }
  };

  return (
    <div className="cart-container">
      <h1 className="cart-title">Shopping Cart</h1>
      {message && <div className="alert alert-success">{message}</div>} {/* Message Display */}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} {/* Error message display */}
      {cart.length === 0 ? (
        <div className="cart-empty"><h4>Your cart is empty</h4>
        <h5>For Fill Your Cart Go To SHOP And Add The Products</h5></div>
      ) : (
        <>
          {cart.map((item, index) => (
            <div key={index} className="row cart-item">
              <div className="col-1">
                <input
                  type="checkbox"
                  checked={selectedProducts.some(product => product.PROD_ID === item.PROD_ID)}
                  onChange={() => handleCheckboxChange(item.PROD_ID, item.quantity, item)}
                />
              </div>
              <div className="col-5">
                <ProductImage className="cart-product-image" imagePath={item.PROD_IMG_URL} alt={item.PROD_DESP} />
              </div>
              <div className="col-6">
                <div className="cart-item-details">
                  <h2 className="cart-product-name">{item.PROD_DESP}</h2>
                  <p className="cart-product-price">Price: ₹ {item.PROD_PRICE}</p>
                  <ul className="list-inline-item">
                    <li className="list-inline-item">Weight :
                        <input type="hidden" name="product-size" id="product-size" value="S"/>
                    </li>
                    <li className="list-inline-item"><span className="btn btn-success btn-size">Kg</span></li>
                  </ul>
                  <div className="quantity-controls">
                    <button className="btn btn-secondary" onClick={() => handleDecrement(item.PROD_ID)}>-</button>
                    <span>{item.quantity}</span>
                    <button className="btn btn-secondary" onClick={() => handleIncrement(item.PROD_ID)}>+</button>
                  </div>
                  {/* <p className="cart-product-total">Total: ₹ {item.PROD_PRICE * item.quantity}</p> */}
                  <p className="cart-product-total">Total: ₹ {(item.PROD_PRICE * item.quantity).toFixed(2)}</p>
                  <button className="btn btn-danger" onClick={() => handleRemove(item.PROD_ID)}>Remove Cart Product</button>
                </div>
              </div>
            </div>
          ))}
          <button className="btn btn-success" onClick={handleSubmit}>Proceed To Purchase</button>
        </>
      )}
    </div>
  );
}
