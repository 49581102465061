import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import services from './apiServices';
import ProductImage from './Productimage';

export default function Productdetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [cartprods, setCartprods] = useState({ prodid: 0, quantityp: 0 });
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Assuming you have a way to check if user is logged in
    const [successMessage, setSuccessMessage] = useState('');
    

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const data = await services.getSINGLEProducts(id);
                setProduct(data);
                console.log("Fetched product data:", data);
            } catch (error) {
                console.error("There was an error fetching the product!", error);
            }
        };

        fetchProduct();
    }, [id]);

    useEffect(() => {
        setCartprods({ prodid: id, quantityp: quantity });
    }, [id, quantity]);

    useEffect(() => {
        // Check if the user is logged in
        const checkLoginStatus = async () => {
            try {
                const response = await services.checkLoginStatus(); // Assuming you have an endpoint to check login status
                setIsLoggedIn(response.isLoggedIn);
            } catch (error) {
                console.error("Error checking login status", error);
            }
        };

        checkLoginStatus();
    }, []);

    const handleIncrement = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };

    const handleDecrement = () => {
        setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : prevQuantity));
    };

    // useEffect({},[prodcartdata])
    const [prodcartdata,setprodcartdata]=useState({
        imgurl:"",
        quantity:0,
        prodid:0,
        dispname:"",
        prodprice:"",
    })
    const handleAddToCart = async (e) => {
        e.preventDefault();
        const cartData = {
            imgurl: product.PROD_IMG_URL,
            dispname: product.PROD_DESP,
            prodprice: product.PROD_PRICE,
            quantity: cartprods.quantityp,
            prodid: cartprods.prodid,
          };
        if (isLoggedIn) {
            try {
                setprodcartdata(cartData)
                const data = await services.sendCARTProducts(cartData);
                console.log(data);
                setSuccessMessage('Product added to cart! Want to Buy More Products Go To SHOP!'); // Set success message
                // Clear message after a few seconds
                setTimeout(() => {
                    setSuccessMessage('');
                }, 7000);
            } catch (error) {
                console.error("There was an error adding the product to the cart!", error);
            }
        } else {
            // Store cart data in localStorage for guest users
            let guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
            guestCart.push({ ...product, quantity });
            localStorage.setItem('guestCart', JSON.stringify(guestCart));
            console.log("Product added to guest cart");

            // Set success message for guest cart
            setSuccessMessage('Product added to guest cart successfully!');

            // Clear success message after 3 seconds
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }
    };
    console.log(prodcartdata)

    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <section className="bg-light">
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-lg-5 mt-5">
                            <div className="card mb-3">
                                <ProductImage className="card-img img-fluid" imagePath={product.PROD_IMG_URL} alt="Product" id="product-detail"/>
                            </div>
                            <div className="row">
                                <div className="col-1 align-self-center">
                                    <a href="#multi-item-example" role="button" data-bs-slide="prev">
                                        <i className="text-dark fas fa-chevron-left"></i>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </div>
                                <div id="multi-item-example" className="col-10 carousel slide carousel-multi-item" data-bs-ride="carousel">
                                    <div className="carousel-inner product-links-wap" role="listbox">
                                        {product.images && product.images.length > 0 && product.images.map((image, index) => (
                                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <a href="#">
                                                            <ProductImage className="card-img img-fluid" imagePath={`${image.IMG_PATH}/${image.IMG_FL_NM}`} alt={`Product Image ${index + 1}`}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-1 align-self-center">
                                    <a href="#multi-item-example" role="button" data-bs-slide="next">
                                        <i className="text-dark fas fa-chevron-right"></i>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <h1 className="h2">{product.PROD_DESP}</h1>
                                    <p className="h3 py-2">₹ {product.PROD_PRICE}</p>
                                    {/* <p className="py-2">
                                        <i className="fa fa-star text-warning"></i>
                                        <i className="fa fa-star text-warning"></i>
                                        <i className="fa fa-star text-warning"></i>
                                        <i className="fa fa-star text-warning"></i>
                                        <i className="fa fa-star text-secondary"></i>
                                        <span className="list-inline-item text-dark">Rating 4.8 | 36 Comments</span>
                                    </p> */}
                                    {/* <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <h6>Brand:</h6>
                                        </li>
                                        <li className="list-inline-item">
                                            <p className="text-muted"><strong>Easy Wear</strong></p>
                                        </li>
                                    </ul> */}

                                    <h5>Description:</h5>
                                    <h4>{product.PROD_DESP} Per KG</h4>
                                    {/* <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <h6>Available Color :</h6>
                                        </li>
                                        <li className="list-inline-item">
                                            <p className="text-muted"><strong>White / Black</strong></p>
                                        </li>
                                    </ul> */}

                                    {/* <h6>Specification:</h6>
                                    <ul className="list-unstyled pb-3">
                                        <li>Lorem ipsum dolor sit</li>
                                        <li>Amet, consectetur</li>
                                        <li>Adipiscing elit, set</li>
                                        <li>Duis aute irure</li>
                                        <li>Ut enim ad minim</li>
                                        <li>Dolore magna aliqua</li>
                                        <li>Excepteur sint</li>
                                    </ul> */}

                                    <form onSubmit={handleAddToCart}>
                                        <input type="hidden" name="product-title" value={product.PROD_DESP}/>
                                        <div className="row">
                                            <div className="col-auto">
                                                <ul className="list-inline pb-3">
                                                    <li className="list-inline-item">Weight :
                                                        <input type="hidden" name="product-size" id="product-size" value="S"/>
                                                    </li>
                                                    <li className="list-inline-item"><span className="btn btn-success btn-size">Kg</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-auto">
                                                <ul className="list-inline pb-3">
                                                    <li className="list-inline-item text-right">
                                                        Quantity
                                                        <input type="hidden" name="product-quanity" id="product-quanity" value={quantity}/>
                                                    </li>
                                                    <li className="list-inline-item"><span className="btn btn-success" onClick={handleDecrement}>-</span></li>
                                                    <li className="list-inline-item"><span className="badge bg-secondary">{quantity}</span></li>
                                                    <li className="list-inline-item"><span className="btn btn-success" onClick={handleIncrement}>+</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row pb-3">
                                            <div className="col d-grid">
                                                <button className="btn btn-success btn-lg" onClick={handleAddToCart}>Add to Cart</button><br></br>
                                                {/* <button className="btn btn-success btn-lg" name="buy" value="buynow">Buy Now</button> */}
                                            </div>
                                        </div>
                                    </form>
                                    {/* Display success message */}
                                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
