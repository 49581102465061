import React, { useState, useEffect, useRef } from 'react';
import services from './apiServices';
import {
    Card, CardContent, Typography, Grid, Avatar, Button, TextField, Divider, Box, Tab, Tabs, CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';

const ProfileContainer = styled('div')({
    maxWidth: 1200,
    margin: '30px auto',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

const AvatarStyled = styled(Avatar)({
    width: 120,
    height: 120,
    marginBottom: 20,
    backgroundColor: '#1976d2',
    color: '#fff',
});

const ActionButtons = styled('div')({
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
});

const TabPanel = styled('div')({
    padding: '20px 0',
});

export default function Userprofile() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(0);
    const formDataRef = useRef(formData);

    useEffect(() => {
        formDataRef.current = formData;
    }, [formData]);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const data = await services.Profiledetails();
                setProfile(data);
                setFormData(data); // Set initial form data from fetched profile
            } catch (err) {
                setError('Error fetching profile details');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
        setSaveSuccess(false); // Clear success message when toggling edit mode
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSave = async () => {
        try {
            console.log('Saving profile data:', formDataRef.current);
            await services.updateProfile(formDataRef.current);
            setIsEditing(false);
            setSaveSuccess(true); // Show success message
            window.location.reload(); // Refresh the page to display updated data
            setTimeout(() => setSaveSuccess(false), 3000);
        } catch (err) {
            console.error('Failed to update profile:', err);
        }
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!profile) {
        return <Typography>Profile data not available, Please Login to View Profile</Typography>;
    }

    return (
        <ProfileContainer>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={3} align="center">
                {/* <AvatarStyled>{profile.USR_F_NM.charAt(0)}  {profile.USR_L_NM.charAt(0)}</AvatarStyled>  */}
                <Typography variant="h5">{profile.USR_F_NM} {profile.USR_L_NM}</Typography>
                <Typography variant="subtitle1">{profile.USR_EMAIL}</Typography>
                </Grid>
            </Grid>

            {saveSuccess && (
                <Typography color="primary" style={{ marginTop: 20, textAlign: 'center' }}>
                    Profile updated successfully!
                </Typography>
            )}

            <Divider style={{ margin: '20px 0' }} />

            <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Personal Information" />
                <Tab label="Address Information" />
            </Tabs>

            <TabPanel hidden={currentTab !== 0}>
                <Card style={{ padding: '20px' }}>
                    <CardContent>
                        <Typography variant="h6">Personal Information</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">First Name:</Typography>
                                {isEditing ? (
                                    <TextField
                                        name="USR_F_NM"
                                        value={formData.USR_F_NM}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography>{profile.USR_F_NM}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Last Name:</Typography>
                                {isEditing ? (
                                    <TextField
                                        name="USR_L_NM"
                                        value={formData.USR_L_NM}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography>{profile.USR_L_NM}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Gender:</Typography>
                                <Typography>{profile.USR_GENDER_CD}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Phone:</Typography>
                                {isEditing ? (
                                    <TextField
                                        name="USR_CELL_PH"
                                        value={formData.USR_CELL_PH}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography>{profile.USR_CELL_PH}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Gmail:</Typography>
                                {isEditing ? (
                                    <TextField
                                        name="USR_EMAIL"
                                        value={formData.USR_EMAIL}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography>{profile.USR_EMAIL}</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </TabPanel>

            <TabPanel hidden={currentTab !== 1}>
                <Card style={{ padding: '20px' }}>
                    <CardContent>
                        <Typography variant="h6">Address Information</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Address Type:</Typography>
                                <Typography>{profile.IS_ADD_TYP}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Address Line 1:</Typography>
                                {isEditing ? (
                                    <TextField
                                        name="ADD_LN1"
                                        value={formData.ADD_LN1}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography>{profile.ADD_LN1 || 'N/A'}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Address Line 2:</Typography>
                                {isEditing ? (
                                    <TextField
                                        name="ADD_LN2"
                                        value={formData.ADD_LN2}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography>{profile.ADD_LN2 || 'N/A'}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Address Line 3:</Typography>
                                {isEditing ? (
                                    <TextField
                                        name="ADD_LN3"
                                        value={formData.ADD_LN3}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography>{profile.ADD_LN3 || 'N/A'}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">City:</Typography>
                                {isEditing ? (
                                    <TextField
                                        name="ADD_CT"
                                        value={formData.ADD_CT}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography>{profile.ADD_CT || 'N/A'}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Pincode:</Typography>
                                {isEditing ? (
                                    <TextField
                                        name="ADD_PIN_ZIP"
                                        value={formData.ADD_PIN_ZIP}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography>{profile.ADD_PIN_ZIP || 'N/A'}</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </TabPanel>

            <ActionButtons>
                <Button variant="outlined" onClick={handleEditToggle}>
                    {isEditing ? 'Cancel' : 'Edit'}
                </Button>
                {isEditing && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save Changes'}
                    </Button>
                )}
            </ActionButtons>
        </ProfileContainer>
    );
}
