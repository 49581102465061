import axios from 'axios';

const services = {
    sendLoginData: async (creds) => {
        try {
            const res = await axios.post(`/api/login`, creds);
            return res.data;
        } catch (error) {
            throw new Error(`Failed to send Data: ${error.message}`);
        }
    },

    getalllogindata: async () => {
        try {
            const res = await axios.get(`/api/login`);
            return res.data;
        } catch (error) {
            throw new Error(`Failed to pull data: ${error.message}`);
        }
    },

    getallregdata: async () => {
        try {
            const res = await axios.get(`/api/register`);
            return res.data;
        } catch (error) {
            throw new Error(`Failed to pull data: ${error.message}`);
        }
    },

    sendRegistrationData: async (data) => {
        try {
            const res = await axios.post('/api/register', data);
            return res.data;
        } catch (error) {
            // Handle error without logging or displaying directly
            throw new Error(`Failed to send registration data: ${error.message}`);
        }
    },

    fetchProductsByCategory: async (prodTypeId) => {
        try {
            const res = await axios.get(`/api/home/products`, {
                params: { prodTypeId }
            });
            return res.data;
        } catch (error) {
            throw new Error(`Failed to fetch products: ${error.message}`);
        }
    },

    getShopData: async (creds) => {
        console.log(creds)
        try {
            const res = await axios.get(`/api/shop?prodtyp=${creds}`);
            return res.data;
        } catch (error) {
            throw new Error(`Failed to fetch shop data: ${error.message}`);
        }
    },

    getAllProducts: async (creds) => {
        console.log(creds)
        try {
            const res = await axios.get('/api/shop');
            return res.data;
        } catch (error) {
            throw new Error(`Failed to fetch all products: ${error.message}`);
        }
    },

    getSINGLEProducts: async (creds) => {
        console.log(creds)
        try {
            const res = await axios.get(`/api/Showproduct?prodid=${creds}`);
            return res.data;
        } catch (error) {
            throw new Error(`Failed to fetch all products: ${error.message}`);
        }
    },

    checkLoginStatus: async (creds) => {
        console.log(creds)
        try {
            const response = await axios.get('/api/Cart/check-login-status', creds);
            return response.data;
        } catch (error) {
            console.error('Error checking login status:', error);
            throw error;
        }
    },

    sendCARTProducts: async (creds) => {
        console.log(creds)
        try {
            const res = await axios.post(`/api/Cart/AddtoCart`, creds);
            return res.data;
        } catch (error) {
            throw new Error(`Failed to fetch all products: ${error.message}`);
        }
    },



    ViewCARTProducts: async (creds) => {
        console.log(creds)
        try {
            const res = await axios.get(`/api/Cart/ViewCart`, creds);
            console.log(res)
            return res.data;
        } catch (error) {
            throw new Error(`Failed to fetch all products: ${error.message}`);
        }
    },

    removeProductFromCart: async (prodid) => {
        console.log(prodid)
        try {
            await axios.post(`/api/Cart/removecart`, { prodid });
        } catch (error) {
            throw new Error(`Failed to remove product from cart: ${error.message}`);
        }
    },


    updateCart: async (prodid, quantity) => {
        console.log("Updating product quantity in cart...", { prodid, quantity });
        try {
            await axios.put('/api/Cart/UpdateCart', { prodid, quantity });
        } catch (error) {
            throw new Error(`Failed to update product quantity in cart: ${error.message}`);
        }
    },

    // Submit/Buy products from the cart
    buyProductFromCart: async (data) => {
        console.log("Buying products from cart...", data);
        try {
            await axios.post('/api/Cart/BuyNow', data);
        } catch (error) {
            throw new Error(`Failed to buy products from cart: ${error.message}`);
        }
    },


    logout: async () => {
        console.log("Logout user sucessfully");
        try {
            await axios.post('/api/logout');
        } catch (error) {
            throw new Error(`Failed to buy products from cart: ${error.message}`);
        }
    },

    viewcartprodinpurchase: async () => {
        console.log("Cart selected products added in purchase...");
        try {
            const res = await axios.get('/api/Cart/viewcartpurchase');
            console.log('Response data:', res.data);
            return res.data;
        } catch (error) {
            console.error(`Failed to buy products from cart: ${error.message}`);
        }
    },

    // Fetch all addresses for the logged-in user
    getAddresses: async () => {
        try {
            const res = await axios.get('/api/Purchase/addresses');
            return res.data;
        } catch (error) {
            console.error("Error fetching addresses:", error);
            throw error;
        }
    },

    addUserAddress: async (useraddresses) => {
        console.log("Add user address...", useraddresses);
        try {
            const res = await axios.post('/api/Purchase/adduseraddresses', useraddresses);
            return res.status;
        } catch (error) {
            console.error('Error adding user address:', error);
            throw error;
        }
    },

    updateUserAddress: async (addressData) => {
        console.log("Update user address...", addressData);
        try {
            const res = await axios.put('/api/Purchase/updateaddresses', addressData);
            return res.status;
        } catch (error) {
            console.error('Error updating user address:', error);
            throw error;
        }
    },


    buyProductFrompurchase: async (orderData) => {
        // console.log("Buying products from purchase...", orderData);
        try {
            const res = await axios.post('/api/Purchase/Buyprodpurchase', orderData);
            return res.data;
        } catch (error) {
            throw new Error(`Failed to buy products from purchase: ${error.message}`);
        }
    },

    getOrderDetails: async (orderId) => {
        try {
            const response = await axios.get(`/api/Purchase/OrderDetails/${orderId}`);
            return response.data; // Return the fetched order details
        } catch (error) {
            throw new Error(`Failed to fetch order details: ${error.message}`);
        }
    },


    getUserSession: async () => {
        try {
            const response = await axios.get('/api/user/session');
            return response.data; // Return the fetched session data
        } catch (error) {
            throw new Error(`Failed to fetch session data: ${error.message}`);
        }
    },

    generateInvoice: async (customerDetails, productDetails, totalAmount) => {
        console.log('Getting data of', customerDetails, productDetails, totalAmount);
        try {
            const response = await axios.post('/api/Generateinvoice/generate-invoice', {
                customerDetails,
                productDetails,
                totalAmount
            }, {
                responseType: 'blob' // Set responseType to 'blob' to handle binary data
            });
            return response; // Return the response for further handling
        } catch (error) {
            console.error('Error generating invoice:', error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    },

    purchasehistory: async () => {
        console.log('Purchase history fetched successfully:');
        try {
            const res = await axios.get('/api/Purchasehistory/purchase-history');
            return res.data;
        } catch (error) {
            console.error(`Error fetching Order History: ${error.message}`);

        }
    },

    downloadInvoice: async (orderData) => {
        console.log(`In downloadInvoice API with data: ${JSON.stringify(orderData)}`);
        try {
            // Make a POST request with the entire `orderData`
            const response = await axios.post('/api/Purchasehistory/generate-invoiceforhistory', orderData, {
                responseType: 'blob' 
            });
            return response;
        } catch (error) {
            console.error('Error downloading the invoice:', error);
            throw error;
        }
    },

    Profiledetails: async () => {
        console.log('Profile Details fetched successfully:');
        try {
            const res = await axios.get('/api/Profile/profile-information');
            return res.data;
        } catch (error) {
            console.error(`Error fetching Profile Details: ${error.message}`);

        }
    },

    updateProfile: async(profileData) => {
        try {
          const response = await axios.put('/api/profile/profile-update', profileData);
          return response.data;
        } catch (error) {
          throw new Error(error.response?.data?.message || 'Failed to update profile');
        }
    },






};



export default services;
